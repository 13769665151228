import { DIMENSIONS } from '@canalplus/dive-utils';
import { Binder } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import classNames from 'classnames';
import type { JSX } from 'react';
import { useOnFocusable } from '../../helpers/hooks/useOnFocusable';
import { MIDDLEWARE_STRATE } from '../../helpers/oneNavigation/middleware';
import CoverImage from '../CoverImage/CoverImage';
import { useIsFrom } from '../Page/useIsFrom';
import styles from './Banner.css';
import type { BannerProps } from './types';

function Banner({
  contents,
  focusManager,
  binderId,
  resizeMode,
}: BannerProps): JSX.Element | null {
  const isFromShowcase = useIsFrom(Template.Showcase);

  useOnFocusable(focusManager, contents.length > 0);

  if (!contents.length) {
    return null;
  }

  return (
    <Binder binderId={binderId} middleware={MIDDLEWARE_STRATE}>
      <ul
        className={classNames(styles.banner__cover, 'banner', {
          [styles['banner__cover--showcase']!]: isFromShowcase,
        })}
      >
        {contents.map((content, index: number) => (
          <li key={`banner-${content.contentID || index}`}>
            <CoverImage
              isBackgroundTransparent={isFromShowcase}
              key={content ? content.contentID : index}
              image={{
                mobile: content.URLImageOptimizedCompact,
                default: content.URLImageOptimizedRegular,
              }}
              onClickData={content}
              dimensions={
                isFromShowcase ? DIMENSIONS.SHOWCASE.banner : DIMENSIONS.BANNER
              }
              label={'label' in content ? content.label : undefined}
              resizeMode={resizeMode}
              isArtDirection
            />
          </li>
        ))}
      </ul>
    </Binder>
  );
}

export default Banner;
